<template>
  <div :id="id" :class="['m-star-company', isPC ? 'z-web' : 'z-phone', theme.primaryColor ? theme.primaryColor : '']">
    <VisualTitle :title="secondTlt" @visual="handleVisual" />
    <div class="u-service-list">
      <VisualItem :visualKeys="list.visualKeys" @visual="handleVisual">
        <div
          v-for="(item, index) in list.value"
          :key="`service-${index}`"
          class="m-service"
        >
          <VisualItem v-if="isPC" :visualKeys="[item.img.visualKeys, item.img_2x.visualKeys, item.link.visualKeys]" @visual="handleVisual">
            <CMSLink
              :link="item.link.value"
            >
              <CMSImageBg
                :img="item.img.value"
                :img2x="item.img_2x.value"
                class-name="u-img"
              />
            </CMSLink>
          </VisualItem>
          <VisualItem v-else :visualKeys="[item.img_2x_h5.visualKeys, item.img_3x_h5.visualKeys, item.link.visualKeys]" @visual="handleVisual">
            <CMSLink
              :link="item.link.value"
            >
              <CMSImageBg
                :img="item.img_2x_h5.value ? item.img_2x_h5.value : item.img.value"
                :img2x="item.img_3x_h5.value ? item.img_3x_h5.value : item.img_2x.value"
                class-name="u-img"
              />
            </CMSLink>
          </VisualItem>
        </div>
      </VisualItem>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import config from './config.json'
import Load from '~/assets/js/load.js'
import { fixConfig } from '~/assets/js/visualFix'
import CMSImageBg from '~/components/common/ImageBg'
import CMSLink from '~/components/common/Link'
import VisualItem from '~/components/common/VisualItem'
import VisualTitle from '~/components/common/VisualTitle'

const componentKey = 'StarCompany'

export default {
  components: {
    CMSImageBg,
    VisualItem,
    CMSLink,
    VisualTitle
  },
  props: {
    id: {
      type: [String, Number],
      default: 'StarCompany'
    },
    mode: {
      type: String,
      default: ''
    },
    reqData: {
      type: Object,
      default() {
        return {}
      }
    },
    isPC: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const data = fixConfig(_.cloneDeep(config[componentKey]), this.mode === 'dev' ? {
      id: componentKey,
      reqData: Load(config, componentKey)
    } : this.$store.getters.getComponent(this.id))

    this.$store.commit('updateComponentById', {
      id: this.id || componentKey,
      data
    })

    return {
      ...data
    }
  },
  computed: {
    theme() {
      return this.$store.state.theme
    }
  },
  methods: {
    handleVisual(obj) {
      this.$emit('visual', obj)
    }
  }
}
</script>

<style lang="postcss">
@import '~/assets/css/4mixin.css';

.m-star-company {
  &.z-phone {
    padding: pxtorem(70) pxtorem(40);
    background: #f7f8fa;

    .u-service-list {
      text-align: left;
      width: 100%;
      margin: pxtorem(80) auto 0;

      .m-service {
        display: inline-block;
        width: pxtorem(323);
        height: pxtorem(132);
        vertical-align: top;
        box-sizing: border-box;
        margin-right: pxtorem(22);
        margin-bottom: pxtorem(22);
        overflow: hidden;
        box-shadow: 0 pxtorem(8) pxtorem(32) rgba(47, 56, 111, 0.05);
        background-color: #fff;

        &:nth-child(2n) {
          margin-right: 0;
        }

        .m-visual-item {
          width: 100%;
        }

        .u-img {
          /* display: block; */
          width: 100%;
          height: pxtorem(132);
          background-size: contain;
        }
      }
    }
  }

  &.z-web {
    /* padding: 60px 0 45px; */
    padding: 90px 0;
    background: linear-gradient(0deg, #f7f8fa, #f7f8fa), #fff;

    .u-service-list {
      text-align: left;
      width: 1200px;
      margin: 60px auto 0;

      .m-service {
        display: inline-block;
        width: 220px;
        height: 122px;
        text-align: center;
        vertical-align: top;
        box-sizing: border-box;
        border: 1px solid #e7ecf3;
        margin-right: 25px;
        margin-bottom: 25px;
        background-color: #fff;
        overflow: hidden;

        &:nth-child(5n) {
          margin-right: 0;
        }

        .m-visual-item {
          width: 100%;
        }

        .u-img {
          /* display: block; */
          width: 220px;
          height: 120px;
          background-size: cover;
        }
      }
    }
  }
}
</style>
